import { Card } from "../components/Card"
import { HTTPCallable } from "../components/HTTPCallable"
import styles from "./Calls.module.css"


export function Calls(){
    return(
        <div className={styles.Card}>
            <Card>
                Alfa
            </Card>
            <Card>
                Beta
            </Card>
            <Card>
            <HTTPCallable
            path="https://api.voicemonkey.io/trigger?access_token=f7251a332510f778b410a71bd1d2059a&secret_token=43ef0f36dab420cd6e0a5bf529264363&monkey=luzcuartoapagar&announcement=Hello%20monkey">
                Apagar
            </HTTPCallable>
            </Card>
            <Card>
            <HTTPCallable
            path="https://api.voicemonkey.io/trigger?access_token=f7251a332510f778b410a71bd1d2059a&secret_token=43ef0f36dab420cd6e0a5bf529264363&monkey=luzcuarto&announcement=Hello%20monkey">
                Prender
            </HTTPCallable>
            </Card>
        </div>
    )
}