import { httpRequest } from "../utils/httpRequests"
import styles from "./components.module.css"
export function HTTPCallable(props){
    const buttonCallback = function(){
        console.log(props.path)
        httpRequest(props.path)
    }
    return(
        <div className={styles.HTTPCallableContainer}>
            {props.children}
            <button className={styles.HTTPCallableButton}
            onClick = {buttonCallback}
            >
                Trigger
            </button>
        </div>
    )
}