import { Calls } from "./Calls";
import styles from "./App.module.css"


function App() {
  return (
    <div className={styles.Main}>
    <div className={styles.App}>

          
      <Calls />

    </div>
    </div>

  );
}

export default App;
